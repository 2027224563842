.ccard-list {
  margin-top: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  padding: 10px;
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;

  .row {
    margin-top: 8px;
    margin-bottom: 8px;
  }
}

.iframe-close {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba($color: #000000, $alpha: 0.3);
}
.iframe-wrapper {
  display: flex;
  position: fixed;
  background: #fff;
  z-index: 1000;
  top: 10%;
  left: 50%;
  margin-left: -250px;
  width: 500px;

  .iframeBlock {
    width: 500px;
    height: 670px;
    border: 1px solid #ccc;

    html {
      overflow: hidden !important;
    }
  }
}
