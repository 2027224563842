.drawer-menu {
  .ant-drawer-content {
    background: #61aa71;
  }

  .ant-drawer-body {
    padding-left: 0;
    padding-right: 0;
  }
}
