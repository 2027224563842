.product {
  margin-top: 30px;

  .product-wrapper {
    border: 1px solid #ccc;
    display: flex;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;
    align-items: center;

    .product-icon {
      font-size: 45px;
    }

    .poduct-price {
      font-size: 30px;
      font-weight: bold;
    }
  }
}

.iframeBlock {
  width: 100%;
  height: 400px;
  border: none;
}
