.header {
  display: flex;
  background: #64b875;
  height: 54px;

  .left-side {
    display: flex;

    .menu-toggle {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      background: none;
      color: #fff;
      font-size: 35px;
      border: none;
    }

    .header-title {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      color: #fff;
      font-weight: bold;
      font-size: 16px;
      margin-left: 15px;
    }
  }

  .right-side {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    margin-top: 8px;
    margin-bottom: 8px;
  }
}
