.wallets-list {
  margin-top: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  padding: 10px;
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;

  .row {
    margin-top: 8px;
    margin-bottom: 8px;
  }

  .wallet-control {
    display: flex;
    flex-direction: row;

    .wallet-icons {
      margin-left: 10px;
      margin-right: 10px;
      cursor: pointer;
      font-size: 25px;
    }
  }
}

.put-it-wallet {
  .row {
    margin-top: 8px;
    margin-bottom: 8px;
  }
}
